// CSS
import "./css/styles.css";

// App-specific
import { PREFERS_REDUCED_MOTION } from "./js/constants.js";
import { addFunkyWaspAnimations } from "./js/add-funky-wasp-animations.js";

// Stuff to do when the DOM is ready
document.addEventListener("DOMContentLoaded", () => {
  // Get the intersection observer going for the funky wasp animations
  // (but only if there isn't a preference for reduced motion.)
  if (PREFERS_REDUCED_MOTION) {
    addFunkyWaspAnimations();
  }
});
