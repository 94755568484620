export function addFunkyWaspAnimations () {
  if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window) {
    const waspRules = [].slice.call(document.querySelectorAll(".rule"));
    let observedElements = 0;

    const intersectionListener = new IntersectionObserver((entries, observer) => {
      for (const entry of entries) {
        if (entry.intersectionRatio) {
          entry.target.classList.add("observed");
          observedElements++;

          if (observedElements === waspRules.length) {
            observer.disconnect();
          }
        }
      }
    });

    for (const waspRule of waspRules) {
      intersectionListener.observe(waspRule);
    }
  }
}
